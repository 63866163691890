const state = {
	languageList: [
		{
			"id": "6",
			"name": "语言类型",
			"type": "language",
			"code": "ssm",
			"value": "ssm",
			"orderNum": 2,
			"remark": null,
			"delFlag": 0
		},{
			"id": "5",
			"name": "语言类型",
			"type": "language",
			"code": 'springboot',
			"value": "springboot",
			"orderNum": 1,
			"remark": null,
			"delFlag": 0
		},{
			"id": "13",
			"name": "语言类型",
			"type": "language",
			"code": 'springboot3',
			"value": "springboot3",
			"orderNum": 1,
			"remark": null,
			"delFlag": 0
		},
		{
			"id": "132",
			"name": "语言类型",
			"type": "language",
			"code": 'springcloud',
			"value": "springcloud",
			"orderNum": 10,
			"remark": "",
			"delFlag": 0
		},
		// {
		// 	"id": "8",
		// 	"name": "语言类型",
		// 	"type": "language",
		// 	"code": "jspm",
		// 	"value": "jspm",
		// 	"orderNum": 4,
		// 	"remark": null,
		// 	"delFlag": 0
		// },
		{
			"id": "9",
			"name": "语言类型",
			"type": "language",
			"code": "net",
			"value": "net",
			"orderNum": 5,
			"remark": null,
			"delFlag": 0
		},
		{
			"id": "10",
			"name": "语言类型",
			"type": "language",
			"code": "php",
			"value": "php",
			"orderNum": 6,
			"remark": null,
			"delFlag": 0
		},
		{
			"id": "11",
			"name": "语言类型",
			"type": "language",
			"code": "thinkphp5",
			"value": "thinkphp5",
			"orderNum": 7,
			"remark": null,
			"delFlag": 0
		},
		{
			"id": "13",
			"name": "语言类型",
			"type": "language",
			"code": "django",
			"value": "django",
			"orderNum": 9,
			"remark": null,
			"delFlag": 0
		},
		// {
		// 	"id": "12",
		// 	"name": "语言类型",
		// 	"type": "language",
		// 	"code": "flask",
		// 	"value": "flask",
		// 	"orderNum": 8,
		// 	"remark": null,
		// 	"delFlag": 0
		// },
		{
			"id": "133",
			"name": "语言类型",
			"type": "language",
			"code": 'nodejs',
			"value": "nodejs",
			"orderNum": 11,
			"remark": "",
			"delFlag": 0
		},
	],
	databaseTypeList: [{
			"id": "14",
			"name": "数据库类型",
			"type": "databaseType",
			"code": 1,
			"value": "mysql",
			"orderNum": 1,
			"remark": null,
			"delFlag": 0
		},
		{
			"id": "15",
			"name": "数据库类型",
			"type": "databaseType",
			"code": 2,
			"value": "sqlserver",
			"orderNum": 2,
			"remark": null,
			"delFlag": 0
		}
	], //数据库列表
	yesOrNoList: [{
			"id": "2",
			"name": "是否",
			"type": "yesOrNo",
			"code": 0,
			"value": "否",
			"orderNum": 0,
			"remark": null,
			"delFlag": 0
		},
		{
			"id": "1",
			"name": "是否",
			"type": "yesOrNo",
			"code": 1,
			"value": "是",
			"orderNum": 0,
			"remark": null,
			"delFlag": 0
		}
	],
	orderStatusList: [{
			"id": "16",
			"name": "订单状态",
			"type": "pOrderStatus",
			"code": 1,
			"value": "待审核",
			"orderNum": 1,
			"remark": null,
			"delFlag": 0
		},
		{
			"id": "17",
			"name": "订单状态",
			"type": "pOrderStatus",
			"code": 2,
			"value": "待开发",
			"orderNum": 2,
			"remark": null,
			"delFlag": 0
		},
		// {
		// 	"id": "18",
		// 	"name": "订单状态",
		// 	"type": "pOrderStatus",
		// 	"code": 3,
		// 	"value": "审核不通过",
		// 	"orderNum": 3,
		// 	"remark": null,
		// 	"delFlag": 0
		// },
		{
			"id": "19",
			"name": "订单状态",
			"type": "pOrderStatus",
			"code": 4,
			"value": "开发中",
			"orderNum": 4,
			"remark": null,
			"delFlag": 0
		},
		{
			"id": "20",
			"name": "订单状态",
			"type": "pOrderStatus",
			"code": 5,
			"value": "开发完成",
			"orderNum": 5,
			"remark": null,
			"delFlag": 0
		},
		{
			"id": "21",
			"name": "订单状态",
			"type": "pOrderStatus",
			"code": 6,
			"value": "返修中",
			"orderNum": 6,
			"remark": null,
			"delFlag": 0
		},
		{
			"id": "23",
			"name": "订单状态",
			"type": "pOrderStatus",
			"code": 7,
			"value": "返修完成",
			"orderNum": 7,
			"remark": null,
			"delFlag": 0
		},
		{
			"id": "24",
			"name": "订单状态",
			"type": "pOrderStatus",
			"code": 8,
			"value": "项目作废",
			"orderNum": 8,
			"remark": null,
			"delFlag": 0
		},
		{
			"id": "25",
			"name": "订单状态",
			"type": "pOrderStatus",
			"code": 9,
			"value": "放弃提货",
			"orderNum": 9,
			"remark": null,
			"delFlag": 0
		}
	],
	frontEndList: [{
			"id": "165",
			"name": "大前端",
			"type": "frontEnd",
			"code": 1,
			"value": "PC端",
			"orderNum": 1,
			"remark": "",
			"delFlag": 0
		},
		{
			"id": "166",
			"name": "大前端",
			"type": "frontEnd",
			"code": 2,
			"value": "微信小程序(WXML)",
			"orderNum": 2,
			"remark": "",
			"delFlag": 0
		},
		{
			"id": "167",
			"name": "大前端",
			"type": "frontEnd",
			"code": 4,
			"value": "微信小程序(uni-app)",
			"orderNum": 4,
			"remark": "",
			"delFlag": 0
		},
		{
			"id": "167",
			"name": "大前端",
			"type": "frontEnd",
			"code": 3,
			"value": "安卓端(Kotlin)",
			"orderNum": 3,
			"remark": "",
			"delFlag": 0
		},
		{
			"id": "167",
			"name": "大前端",
			"type": "frontEnd",
			"code": 5,
			"value": "安卓端(uni-app)",
			"orderNum": 5,
			"remark": "",
			"delFlag": 0
		}
	],
}
export default {
	state,
}