import request from '@/utils/request'

export function getList(params) {
  return request({
    url: 'role/list',
    method: 'get',
    params
  })
}

export function getRoleUserList(params, id) {
  return request({
    url: 'role/userList/' + id,
    method: 'get',
    params
  })
}

export function saveRoleUser(ids) {
  return request({
    url: 'role/saveRoleUser',
    method: 'post',
    data: ids
  })
}

export function save(url,params) {
  return request({
    url: url,
    method: 'post',
    data:params
  })
}

export function updateStatus(params) {
  return request({
    url: 'role/updateStatus',
    method: 'post',
    params
  })
}

export function remove(roleId) {

  return request({
    url: 'role/delete',
    method: 'delete',
    data: roleId
  })
}

export  function roleTreeListByIdUser(idUser,params={}){
  return request({
    url: 'user/roles/'+idUser,
    method: 'get',
    params: {
      params
    }
  })
}

export function savePermissons(params) {
  return request({
    url: 'role/saveMenu',
    method: 'post',
    data:params
  })
}

export function menuTreeListByRoleId(params){
  return request({
    url: 'role/info' + '/' + params,
    method: 'get',
  })
}
