import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'
import router from '@/router'
import { message, Modal } from 'ant-design-vue';
// create an axios instance
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
	withCredentials: true, // send cookies when cross-domain requests
	timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
	config => {
		// do something before request is sent
		var token = getToken()
		if (token) {
			config.headers['token'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
		}
		return config
	},
	error => {
		// do something with request error
		console.log(error) // for debug
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	* If you want to get http information such as headers or status
	* Please return  response => response
	*/

	/**
	* Determine the request status by custom code
	* Here is just an example
	* You can also judge the status by HTTP Status Code
	*/
	response => {
		const res = response.data
		if(response.headers.token){
			//如果后台通过header返回token，说明token已经更新，则更新客户端本地token
			store.dispatch('user/updateToken',{token:response.headers.token})
		}

		if(res.code==200){
			return res
		}
		// if the custom code is not 0, it is judged as an error.
		else if (res.code !== 200) {
			message.error(res.message || 'error')
			// 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
			if (res.code === 50008 || res.code === 50012 || res.code === 50014 || res.code === 401) {
				// to re-login
				Modal.confirm({
					content: '您将重新登录，以继续操作',
					title: '登录超时',
					okText: '重新登录',
					cancelText: '取消',
					onOk(){
						store.dispatch('user/resetToken').then(() => {
							location.reload()
						})
					}
				})
			}
			return Promise.reject(res)
		} else {
			return res
		}
	},
	error => {
		if(error.response.status === 401){
			store.dispatch('user/logout').then(()=>{
				router.replace({
					path: '/login',
				})
			})
			return
		}
		message.error(error.message)
		return Promise.reject(error)
	}
)

export default service
