<template>
	<div>
		<a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol"
			labelAlign="left">
			<a-row type="flex" justify="start" align="top">
				<a-col :span="12">
					<a-form-model-item label="项目名称" prop="projectName" :label-col="labelCol2"
						:wrapper-col="wrapperCol2">
						<a-input v-model="form.projectName" minlength="1" style="width: 100%" />
					</a-form-model-item>
				</a-col>
				<a-col :span="6">
					<a-form-model-item label="语言框架" prop="lang">
						<a-select v-model="form.lang" style="width: 100%" placeholder="语言框架">
							<a-select-option v-for="(item,index) in languageList" :value="item.code">
								{{item.value}}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col :span="6">
					<a-form-model-item label="数据库" prop="database">
						<a-select v-model="form.database" style="width: 100%" placeholder="数据库">
							<a-select-option v-for="(item,index) in databaseTypeList" :value="item.code">
								{{item.value}}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<!-- <a-col :span="4">
					<a-form-model-item label="爬虫" prop="isSpider">
						<a-select v-model="form.isSpider" style="width: 100%" placeholder="爬虫">
							<a-select-option v-for="(item,index) in yesOrNoList" :value="item.code">
								{{item.value}}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col> -->
				<!-- <a-col :span="4" v-if="isMasterAccount!=0">
					<a-form-model-item label="价格(元)" prop="totalPrice">
						<a-input v-model="form.totalPrice" minlength="1" type="number" placeholder="系统自动获取" disabled />
					</a-form-model-item>
				</a-col> -->
			</a-row>
			<a-row type="flex" justify="start" align="top">

				<a-col :span="6">
					<a-form-model-item label="大前端" prop="bigFrontEnd">
						<a-select v-model="form.bigFrontEnd" style="width: 100%" placeholder="大前端">
							<a-select-option v-for="(item,index) in frontEndList" :value="item.code">
								{{item.value}}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col :span="6">
					<a-form-model-item label="私有编号" prop="myCode">
						<a-input v-model="form.myCode" minlength="1" placeholder="请输入" style="width: 100%" />
					</a-form-model-item>
				</a-col>
				<a-col :span="6">
					<a-form-model-item label="高校名称" prop="universityName">
						<a-input v-model="form.universityName" minlength="1" placeholder="请输入" style="width: 100%" />
					</a-form-model-item>
				</a-col>
				<a-col :span="6">
					<a-form-model-item label="交付日期" prop="deliverDate">
						<a-date-picker format="YYYY-MM-DD" placeholder="请选择" style="width: 100%" :disabledDate="disabledDate"
							:value="parseDate(form.deliverDate)" @change="onChange" />
					</a-form-model-item>
				</a-col>
				<a-col :span="6" v-if="form.bigFrontEnd>1">
					<a-form-model-item label="多端" prop="multiTerminal">
						<a-select v-model="form.multiTerminal" style="width: 100%" placeholder="多端">
							<a-select-option v-for="(item,index) in yesOrNoList" :value="item.code">
								{{item.value}}
							</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col :span="6" v-if="form.bigFrontEnd>1">
				</a-col>
				<a-col :span="6" v-if="form.bigFrontEnd>1">
				</a-col>
				
				<a-col :span="12" v-if="showType">
					<a-form-model-item label="需求上传" prop="reqDocument" :label-col="labelCol2" :wrapper-col="wrapperCol2"
						labelAlign="left">
						<a-upload-dragger name="file" :multiple="false" :action="action" @change="handleChange"
							 :defaultFileList="fileList">
							<p class="ant-upload-drag-icon">
								<a-icon type="inbox" />
							</p>
							<p class="ant-upload-text">
								将文件拖到此处，或<em>点击上传</em>
							</p>
						</a-upload-dragger>
					</a-form-model-item>
				</a-col>
			</a-row>
			<!-- <a-form-model-item>
				<a-button type="primary" @click="save" ghost>
					保存
				</a-button>
				<a-button type="primary" style="margin-left: 10px;" @click="restForm" ghost>
					重置
				</a-button>
			</a-form-model-item> -->
		</a-form-model>
	</div>
</template>

<script>
	import moment from 'moment';
	import {
		save,
	} from '@/api/order/myOrder.js'
	export default {
		data() {
			return {
				action: process.env.VUE_APP_BASE_API + '/oss/upload',
				form: {
					projectName: '',
					lang: '',
					database: 1,
					// finishDate: '',
					deliverDate: null,
					// orderDesc: '',
					bigFrontEnd: '',
					universityName: '',
					myCode: '',
					reqDocument: '',
					// isSpider: 0,
					// isBigData: 0,
					multiTerminal: 0
				},
				labelCol: {
					span: 6,
					offset: 3,
					push: 1
				},
				wrapperCol: {
					span: 12,
					offset: 3
				},
				labelCol2: {
					span: 3,
					offset: 2
				},
				labelCol3: {
					span: 3,
					offset: 2
				},
				wrapperCol2: {
					span: 18,
					offset: 1
				},
				wrapperCol3: {
					span: 17,
					offset: 2
				},
				peersList: [], //同行列表

				rules: {
					projectName: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					lang: [{
						required: true,
						message: '请选择',
						trigger: 'blur'
					}],
					database: [{
						required: true,
						message: '请选择',
						trigger: 'blur'
					}],
					finishDate: [{
						required: true,
						message: '请选择',
						trigger: 'blur'
					}],
					bigFrontEnd: [{
						required: true,
						message: '请输入',
						trigger: 'blur'
					}],
					// isSpider: [{
					// 	required: true,
					// 	message: '请选择',
					// 	trigger: 'blur'
					// }],
					// isBigData: [{
					// 	required: true,
					// 	message: '请选择',
					// 	trigger: 'blur'
					// }],
					reqDocument: [{
						required: true,
						message: '请上传',
						trigger: 'blur'
					}],
				},
				fileList: [],
				showType: false
			}
		},
		created() {},
		computed: {
			roleType() {
				return this.$store.state.user.roleType
			},
			isMasterAccount() {
				return this.$store.state.user.isMasterAccount
			},
			languageList() {
				return this.$store.state.dict.languageList
			},
			databaseTypeList() {
				return this.$store.state.dict.databaseTypeList
			},
			yesOrNoList() {
				return this.$store.state.dict.yesOrNoList
			},
			frontEndList() {
				return this.$store.state.dict.frontEndList
			},
		},
		methods: {
			moment,
			disabledDate (current) {
				return current && current < moment().subtract(1, 'days').endOf('day')
			},
			getInfo(row) {
				this.showType = false
				if (row) {
					if (!row.deliverDate) {
						row.deliverDate = ''
					}
					this.fileList = [{
						name: row.projectName,
						uid: row.projectId,
						url: row.reqDocument,
						status: 'done',
					}]
					this.$forceUpdate()
					this.form = row
				} else {
					this.restForm()

					this.$forceUpdate()
				}
				this.showType = true
			},
			parseDate(dateString) {
				// 将字符串转换为Moment对象，DatePicker可以识别
				if (dateString) {
					return this.moment(dateString, 'YYYY-MM-DD');
				}

			},
			restForm() {
				this.showType = false
				this.fileList = []
				this.form = {
					projectName: '',
					lang: '',
					database: 1,
					deliverDate: this.getDate(),
					bigFrontEnd: '',
					universityName: '',
					multiTerminal: 0,
					reqDocument: ''
				}
				setTimeout(()=>{
					this.showType = true
				},0)
			},
			getDate() {
				var date = new Date();
				return date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' + String(Number(date.getMonth()) +
					1) : String(Number(date.getMonth()) + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() :
					date.getDate())
			},
			save() {
				this.$refs['form'].validate((valid) => {
					if (valid) {
						let data = this.form
						let url = '/project/add'
						if (this.form.orderId) {
							url = '/project/update'
						}
						save(url, data).then(response => {
							this.$message.success('操作成功')
							this.$emit('change')
						})
					}
				})
			},
			handleChange({
				file,
				fileList
			}) {
				if (file.status == 'done') {
					// 处理上传完成后的结果
					this.form.reqDocument = file.response.data
					this.fileList = [file]
				}
				if (file.status == 'removed') {
					this.form.reqDocument = ''
					this.fileList = []
				}
			},
			onChange(date, e) {
				this.form.deliverDate = e
			},
		}
	}
</script>

<style>
</style>