import request from '@/utils/request'

export function getList() {
  return request({
    url: 'menu/list',
    method: 'get'
  })
}

export function listForRouter(params) {
  return request({
    url: 'menu/data',
    method: 'get',
  })
}

export function save(url, data) {
  return request({
    url: url,
    method: 'post',
    data: data
  })
}

export function delMenu(id) {
  return request({
    url: 'menu/delete',
    method: 'delete',
    params: {
      menuId: id
    }
  })
}
export function menuTreeListByRoleId(roleId) {
  return request({
    url: 'menu/menuTreeListByRoleId',
    method: 'get',
    params: {
      roleId: roleId
    }
  })
}
