<template>
	<a-layout id="components-layout-demo-custom-trigger">
		<a-layout-sider v-model="collapsed" :trigger="null" collapsible>
			<!-- <div class="logo" /> -->
			<a-menu mode="inline" :default-selected-keys="[defaultSelectedKeys]" :defaultOpenKeys="[defaultOpenKeys]"
				@click="menuClick">
				<a-sub-menu v-for="(item,index) in routes" v-if="!item.hidden" :key="item.path">
					<span slot="title" class="title-box">
						<a-icon :type="item.icon" />
						<span class="titles">{{item.name}}</span>
						<a-icon v-if="!collapsed" class="icons" type="caret-right" />
					</span>
					<a-menu-item :key="items.path" v-for="(items,indexs) in item.children">
						{{items.name}}
					</a-menu-item>
				</a-sub-menu>
			</a-menu>
		</a-layout-sider>
		<a-layout>
			<a-layout-header style="background: #fff; padding: 0">
				<div style="display: flex;align-items: center;justify-content: space-between;padding: 0 24px;">
					<a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'"
						@click="() => (collapsed = !collapsed)" />
					<div>
						<a @click="instructionClick" style="padding: 0 10px 0 0;color: #f00;">使用说明</a>
						<a-dropdown>
							<a class="ant-dropdown-link" @click="e => e.preventDefault()">
								{{name}} <a-icon type="down" />
							</a>
							<a-menu slot="overlay">
								<a-menu-item @click="userClick">
									用户信息
								</a-menu-item>
								<a-menu-divider />
								<a-menu-item @click="loginout">
									退出登录
								</a-menu-item>
							</a-menu>
						</a-dropdown>
					</div>
				</div>
			</a-layout-header>
			<a-layout-content
				:style="{ margin: '24px 16px', padding: '24px 24px 6px', background: '#fff', minHeight: '280px' }">
				<router-view class="router-view" />
			</a-layout-content>
		</a-layout>
		<a-modal :title="'用户信息'" :visible="userVisible" @cancel="userVisible=false" cancelText="关闭" @ok="updatePassword"
			okText="修改密码" :maskClosable="false">
			<a-form-model :model="profile" :labelCol="labelCol" :wrapperCol="wrapperCol">
				<a-form-model-item label="账号">
					{{profile.userName}}
				</a-form-model-item>
				<a-form-model-item label="姓名">
					{{profile.fullName}}
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<a-modal :title="'修改密码'" :visible="passwordVisible" @cancel="handleCancel" cancelText="取消" @ok="passwordSave"
			okText="修改密码" :maskClosable="false">
			<a-form-model ref="passwordForm" :model="passwordForm" :rules="rules">
				<a-form-model-item label="当前密码" prop="password" style="margin: 0">
					<a-input v-model="passwordForm.password" minlength="1" type="password" placeholder="请输入当前账号密码" />
				</a-form-model-item>
				<a-form-model-item label="新密码" prop="newPassword" style="margin: 0">
					<a-input v-model="passwordForm.newPassword" minlength="1" type="password"
						placeholder="新密码与当前密码不能相同" />
				</a-form-model-item>
				<a-form-model-item label="确认密码" prop="newPassword2" style="margin: 0">
					<a-input v-model="passwordForm.newPassword2" minlength="1" type="password"
						placeholder="确认密码跟新密码保持一致" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<a-modal :title="'使用说明'" :visible="instructionVisible" width="60%" @cancel="instructionVisible = false"
			cancelText="取消" okText="保存" :maskClosable="false" :footer="null">
			<div v-html="instructionForm.content"></div>
		</a-modal>
	</a-layout>
</template>

<script>
	import {
		updatePwd
	} from '@/api/user'
	import {
		instructionDetail,
	} from '@/api/order/myOrder'
	import router from '@/router'
	export default {
		name: 'Layout',
		data() {
			return {
				collapsed: false,
				userVisible: false,
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 14
				},
				passwordForm: {
					password: '',
					newPassword: '',
					newPassword2: '',
				},
				rules: {
					password: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						},
						{
							min: 6,
							message: '请输入6位及以上',
							trigger: 'blur'
						}
					],
					newPassword: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						},
						{
							min: 6,
							message: '请输入6位及以上',
							trigger: 'blur'
						}
					],
					newPassword2: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						},
						{
							min: 6,
							message: '请输入6位及以上',
							trigger: 'blur'
						}
					],
				},
				passwordVisible: false,
				defaultOpenKeys: '/',
				defaultSelectedKeys: 'project',
				instructionForm: {},
				instructionVisible: false,
			}
		},
		computed: {
			routes() {
				return this.$store.state.menu.routes
			},
			profile() {
				return this.$store.state.user.profile
			},
			name() {
				return this.$store.state.user.name
			}
		},
		created() {
			for (let x in this.routes) {
				if (this.routes[x].children && this.routes[x].children.length) {
					for (let i in this.routes[x].children) {
						if (this.routes[x].children[i].path == this.$router.currentRoute.path) {
							this.defaultOpenKeys = this.routes[x].path
							this.defaultSelectedKeys = this.routes[x].children[i].path
						}
					}
				}
			}
		},
		methods: {
			instructionClick() {
				instructionDetail().then(res => {
					res.data.content = res.data.content.replace(/img src/gi,"img style=\"width:100%;\" src");
					this.instructionForm = res.data
					this.instructionVisible = true
				})
			},
			handleCancel() {
				this.passwordVisible = false
				this.$refs.passwordForm.resetFields()
			},
			userClick() {
				this.userVisible = true
			},
			updatePassword() {
				this.passwordForm = {
					password: '',
					newPassword: '',
					newPassword2: '',
				}
				this.passwordVisible = true
			},
			passwordSave() {
				let that = this
				this.$refs.passwordForm.validate(valid => {
					if (valid) {
						if (this.passwordForm.newPassword != this.passwordForm.newPassword2) {
							this.$message.error("两次密码输入不同")
							return false
						}
						updatePwd(this.passwordForm).then(res => {
							this.$message.success("修改密码成功")
							setTimeout(function() {
								that.loginout()
							}, 1000)
						})
					}
				});
			},
			menuClick(e) {
				this.$router.push(`${e.key}`)
			},
			loginout() {
				this.$store.dispatch('user/logout').then(obj => {
					this.$router.push({
						path: '/login',
					})
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	#components-layout-demo-custom-trigger {
		max-height: 100vh;
		min-height: 100vh;
	}

	#components-layout-demo-custom-trigger .trigger {
		font-size: 18px;
		line-height: 64px;
		padding: 0;
		cursor: pointer;
		transition: color 0.3s;
	}

	#components-layout-demo-custom-trigger .trigger:hover {
		color: #1890ff;
	}

	#components-layout-demo-custom-trigger .logo {
		height: 32px;
		background: none;
		margin: 16px;
	}

	.ant-layout-sider {
		background: #0083E6;
	}

	.ant-layout-sider .ant-menu {
		background: transparent;
		border: none;
	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-submenu {
		padding: 20px 0;
	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-submenu .title-box {
		color: #BFBFBF;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-submenu .title-box .titles {
		padding: 10px 0;
	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-submenu .title-box i {
		font-size: 25px;
		margin: 0;
	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-submenu .title-box .icons {
		font-size: 14px;
		position: absolute;
		right: 20px;
		bottom: 23px;
		transition: 0.2s all;
	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-submenu-active .title-box {
		color: #fff;
	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-submenu-active .title-box .icons,
	.ant-menu-submenu-open .title-box .icons {
		transform: rotate(90deg);

	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-submenu:hover .title-box {
		color: #fff;
	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-submenu .ant-menu-submenu-arrow {
		display: none;
	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-submenu .ant-menu-submenu-title {
		height: auto;
	}
	.ant-layout-sider .ant-menu.ant-menu-inline-collapsed /deep/.ant-menu-submenu .ant-menu-submenu-title {
		height: 40px;
	}
	

	.ant-layout-sider .ant-menu /deep/.ant-menu-sub {
		background: #0A64A7;
		// padding: 10px 0;
	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-sub .ant-menu-item {
		width: 90%;
		margin: 10px auto;
		border-radius: 5px;
		background: transparent;
		color: #fff;
		padding: 0 !important;
		text-align: center;
	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-sub .ant-menu-item.ant-menu-item-selected {
		background: #0083E6;
	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-sub .ant-menu-item::after {
		border: none;
	}

	.ant-layout-sider .ant-menu /deep/.ant-menu-sub .ant-menu-item:hover {
		background: #0083E680;

	}
</style>