import Vue from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import store from './store'
import '@/permission' // permission control
import router from './router'
import 'ant-design-vue/dist/antd.css';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.config.productionTip = false;

Vue.use(Antd);

new Vue({
	store,
	router,
	render: h => h(App),
}).$mount('#app')