import {
	listForRouter
} from '@/api/menu'
import {
	traverseRoutes
} from '@/utils/route'
import {
	constantRoutes
} from '@/router'
const state = {
	routes: [],
	addRoutes: [],
	allRoute: [],
	type: 0,
	systemName: '主系统',
	permissions: null,
}
const mutations = {
	SET_ROUTES: (state, routes) => {
		state.addRoutes = routes
		state.routes = constantRoutes.concat(routes);
	},
	SET_PERMISSIONS: (state, permissions) => {
		state.permissions = permissions
	},
	SET_TYPE: (state, type) => {
		state.type = type
	}
}
//菜单改造
const makeMenu = (menus, type = '7') => {
	let data = []
	for (let i in menus) {
		let v = menus[i]
		// let typeArr=v.appType.split(",");
		// if(typeArr.includes(type)){
		let obj = {}
		obj.children = []
		obj.component = v.component
		obj.hidden = v.hidden
		obj.id = v.menuId
		obj.icon = v.icon
		obj.meta = {}
		obj.meta.icon = v.icon
		obj.meta.title = v.code
		obj.name = v.name
		obj.num = v.displayOrder
		obj.parentId = v.parentId
		obj.path = v.url
		obj.appType = v.appType
		if (v.children.length) {
			for (let j in v.children) {

				let v1 = v.children[j]
				let obj1 = {}
				obj1.children = []
				obj1.component = v1.component
				obj1.hidden = v1.hidden
				obj1.id = v1.menuId
				obj1.meta = {}
				obj1.meta.icon = v1.icon
				obj1.meta.title = v1.code
				obj1.name = v1.name
				obj1.num = v1.displayOrder
				obj1.parentId = v1.parentId
				obj1.path = v1.url
				obj1.appType = v1.appType
				obj.children.push(obj1)
			}
		}
		data.push(obj)

	}

	return data
}

const actions = {

	getSideMenus({
		commit,
		state,
		dispatch
	}, type) {
		return new Promise((resolve, reject) => {
			listForRouter().then(response => {
				commit('SET_PERMISSIONS', response.data.permissions)
				const menus = response.data.menus
				let data = makeMenu(menus, type);
				let remoteroutes = traverseRoutes(data)
				state.allRoute = remoteroutes //拿到后台返回的菜单
				dispatch('updateSideMenus', 7)
				resolve(remoteroutes);
			}).catch(error => {
				reject(error)
			})
		})
	},
	updateSideMenus({
		commit,
		state,
		dispatch
	}, type) {
		let menu = state.allRoute.filter(item => {
			//-1就是首页
			let typeArr = item.appType.split(',');
			if (item.appType == -1 || item.appType != undefined && item.appType != null && item.appType
				.includes(type)) {
				return true
			}
		})
		commit('SET_TYPE', type)
		commit('SET_ROUTES', menu)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}