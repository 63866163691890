<template>
	<div>
		<a-form-model layout="inline" :model="listQuery" @submit="search" @submit.native.prevent
			style="margin: 0 0 10px">
			<a-form-model-item>
				<a-input v-model="listQuery.userName" placeholder="账号" allowClear>
					<a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
				</a-input>
			</a-form-model-item>
			<a-form-model-item>
				<a-input v-model="listQuery.fullName" placeholder="姓名" allowClear>
					<a-icon slot="prefix" type="smile" style="color:rgba(0,0,0,.25)" />
				</a-input>
			</a-form-model-item>
			<a-form-model-item>
				<a-button-group>
					<a-button type="primary" html-type="submit" icon="search" class="add-btn">
						搜索
					</a-button>
				</a-button-group>
			</a-form-model-item>
		</a-form-model>
		<a-button-group style="margin: 0 0 20px;">
			<a-button type="primary" @click="add" v-permission="['/user/add']" class="add-btn">
				新增
			</a-button>
		</a-button-group>
		<a-table :columns="columns" :row-key="record => record.userId" :data-source="list" :pagination="false" bordered
			:loading="loading">
			<template slot="roleNameList" slot-scope="text"> <a-tag v-for="(item,index) in text" :key="index"
					color="green">{{item}}</a-tag> </template>
			<template slot="userStatus" slot-scope="text,record,index">
				<a-tag color="green" v-if="text=='正常'">
					{{text}}
				</a-tag>
				<a-tag color="red" v-else>
					{{text}}
				</a-tag>
			</template>
			<template slot="queryPermStatus" slot-scope="text,record,index">
				<a-tag color="cyan" v-if="text=='个人'">
					{{text}}
				</a-tag>
				<a-tag color="blue" v-else>
					{{text}}
				</a-tag>
			</template>
			<template slot="subAllOrderQuery" slot-scope="text,record,index"> <a-switch :defaultChecked="text == 1"
					@change="(e)=>changeUserQuery(e,record)" /> </template>
			<template slot="subDownloadCode" slot-scope="text,record,index"> <a-switch :defaultChecked="text == 1"
					@change="(e)=>changeUserDownload(e,record)" /> </template>
			<template slot="subDeploy" slot-scope="text,record,index"> <a-switch :defaultChecked="text == 1"
					@change="(e)=>changeUserDeploy(e,record)" /> </template>
			<template slot="operation" slot-scope="text, record, index">
				<div class="editable-row-operations">
					<!-- <a-button type="primary" v-permission="['/user/check']" @click="view(record.userId)" ghost
						style="margin: 0 10px 0 0;" size="small">
						查看
					</a-button> -->
					<a-button type="primary" v-permission="['/user/edit']" @click="edit(record)" ghost
						style="margin: 0 10px 0 0;" size="small">
						修改
					</a-button>
					<a-button type="danger" v-permission="['/user/delete']" @click="remove(record.userId)" ghost
						style="margin: 0 10px 0 0;" size="small">
						删除
					</a-button>
					<!-- <a-button type="primary" v-permission="['/user/resetPassword']" @click="resetPassword(record.userId)" ghost
						style="margin: 0 10px 0 0;" size="small">
						密码重置
					</a-button> -->
					<a-dropdown :trigger="['click']" v-permission="['/user/openRole']">
						<a class="ant-dropdown-link" @click="e => e.preventDefault()">
							更多操作 <a-icon type="down" />
						</a>
						<a-menu slot="overlay">
							<a-menu-item
								@click.native="openRole(record.userId,record.isMasterAccount)">
								角色分配
							</a-menu-item>
							<a-menu-divider />
							
						</a-menu>
					</a-dropdown>
				</div>
			</template>
		</a-table>
		<a-pagination size="small" v-model="listQuery.page" :total="total" :defaultPageSize="listQuery.limit" :show-total="total => `共${total}条`"
			show-less-items style="padding: 20px 0;" @change="getList()" />
		<a-modal :title="title" :visible="visible" :confirm-loading="confirmLoading" @ok="handleOk"
			:maskClosable="false" cancelText="重置密码" okText="保存" @cancel="handleCancel"
			:ok-button-props="{ props: { disabled: !isAdd } }" :cancel-button-props="{ props: { disabled: isAdd||!showPassword } }">
			<a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item ref="userName" label="账号" prop="userName">
					<a-input v-model="form.userName" minlength="1"
						:disabled="!isAdd||(isAdd&&!showPassword)?true:false" />
				</a-form-model-item>
				<a-form-model-item label="姓名" prop="fullName">
					<a-input v-model="form.fullName" minlength="1" :disabled="!isAdd" />
				</a-form-model-item>
				<a-form-model-item label="密码" prop="password" v-if="showPassword">
					<a-input v-model="form.password" minlength="1" type="password" :disabled="!isAdd" />
				</a-form-model-item>
				<a-form-model-item label="确认密码" prop="rePassword" v-if="showPassword">
					<a-input v-model="form.rePassword" minlength="1" type="password" :disabled="!isAdd" />
				</a-form-model-item>
				<a-form-model-item label="状态" prop="status" v-if="!showPassword">
					<a-radio-group v-model="form.status">
						<a-radio :value="1">
							正常
						</a-radio>
						<a-radio :value="0">
							锁定
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="查询" prop="queryPerm" v-if="!showPassword">
					<a-radio-group v-model="form.queryPerm">
						<a-radio :value="0">
							个人
						</a-radio>
						<a-radio :value="1">
							所有
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
			</a-form-model>
			<template #footer>
				<a-button type="danger" v-if="isAdd&&!showPassword" @click="resetPassword" v-permission="['/user/resetPassword']">重置密码</a-button>
				<a-button type="primary" @click="handleOk">保存</a-button>
			</template>
		</a-modal>
		<a-modal :title="'角色分配'" :visible="roleDialog.visible" :confirm-loading="confirmLoading" @ok="setRole"
			:maskClosable="false" cancelText="取消" okText="保存" @cancel="roleDialog.visible=false" width="45%">
			<a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item ref="userName" label="账号" prop="userName">
					<a-checkbox-group v-model="roleDialog.checkedComRoleKeys" @change="handleCheckedComRole">
						<a-checkbox v-for="rolename in roleDialog.roles.commonRoles" :key="rolename.roleId"
							:value="rolename.roleId">{{ rolename.roleName }}</a-checkbox>
					</a-checkbox-group>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	// 权限判断指令
	import permission from '@/directive/permission/index.js'
	import {
		getList,
		changeStatus,
		changeQuery,
		changeDownload,
		changeDeploy,
		check,
		save,
		remove,
		setRole,
		restPassword
	} from '@/api/system/user'
	import {
		roleTreeListByIdUser,
		getList as roleList
	} from '@/api/system/role'
	export default {
		directives: {
			permission
		},
		data() {
			return {
				list: [],
				total: 0,
				listQuery: {
					page: 1,
					limit: 10
				},
				isMasterAccount: 0,
				loading: false,
				title: '',
				visible: false,
				confirmLoading: false,
				isAdd: true,
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 14
				},
				form: {
					userName: '', //用户
					fullName: '', //真实姓名
					password: '', //密码
					rePassword: '', //确认密码
					status: 1
				},
				showPassword: false,
				rules: {
					userName: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						},
						{
							min: 4,
							message: '账号至少包含4个字符',
							trigger: 'blur'
						}
					],
					fullName: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						},
						{
							min: 2,
							message: '姓名至少包含2个字符',
							trigger: 'blur'
						}
					],
					password: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						},
						{
							min: 6,
							message: '密码至少包含6个字符',
							trigger: 'blur'
						}
					],
					rePassword: [{
							required: true,
							message: '请输入',
							trigger: 'blur'
						},
						{
							min: 6,
							message: '密码至少包含6个字符',
							trigger: 'blur'
						}
					]
				},
				columns: [{
						title: '账号',
						dataIndex: 'userName',
					}, {
						title: '姓名',
						dataIndex: 'fullName',
					},
					// {
					// 	title: '性别',
					// 	dataIndex: 'gender',
					// 	scopedSlots: {
					// 		customRender: 'gender'
					// 	},
					// },
					{
						title: '角色',
						dataIndex: 'roleNameList',
						scopedSlots: {
							customRender: 'roleNameList'
						},
						className: 'tableHiden',
					},
					{
						title: '用户状态',
						dataIndex: 'userStatus',
						scopedSlots: {
							customRender: 'userStatus'
						},
					},
					{
						title: '订单查看',
						dataIndex: 'queryPermStatus',
						scopedSlots: {
							customRender: 'queryPermStatus'
						},
					},
					// {
					// 	title: '查看所有订单',
					// 	dataIndex: 'subAllOrderQuery',
					// 	scopedSlots: {
					// 		customRender: 'subAllOrderQuery'
					// 	},
					// 	className: 'tableHiden',
					// },
					// {
					// 	title: '下载代码',
					// 	dataIndex: 'subDownloadCode',
					// 	scopedSlots: {
					// 		customRender: 'subDownloadCode'
					// 	},
					// 	className: 'tableHiden',
					// },
					// {
					// 	title: '部署预览',
					// 	dataIndex: 'subDeploy',
					// 	scopedSlots: {
					// 		customRender: 'subDeploy'
					// 	},
					// 	className: 'tableHiden',
					// },
					{
						title: '操作',
						dataIndex: 'operation',
						scopedSlots: {
							customRender: 'operation'
						},
					}
				],
				selRows: [],
				roleDialog: {
					visible: false,
					roles: {
						allRoles: [], //全部的角色
						systemRoles: [], //系统角色
						commonRoles: [], //普通角色
						privateRoles: [], //私有角色
					},
					checkedSysRoleKeys: '', //系统选中角色
					checkedComRoleKeys: [], //普通选中角色
					checkedPrivateRoleKeys: [], //私有选中角色
				},
			}
		},

		created() {
			this.getList()
			this.createTables()
		},
		computed: {
			userId() {
				return this.$store.state.user.userId
			},
		},
		methods: {
			handleSelectionChange(e) {
				this.selRows = e
			},
			createTables() {
				for (let x in this.columns) {
					if (this.userId == 1) {
						if (this.columns[x].dataIndex == 'roleNameList') {
							delete this.columns[x].className
						}
					} else {
						if (this.columns[x].dataIndex == 'subAllOrderQuery' || this.columns[x].dataIndex ==
							'subDownloadCode' || this.columns[x].dataIndex == 'subDeploy') {
							delete this.columns[x].className
						}
					}
				}
			},
			search() {
				this.listQuery.page = 1
				this.getList()
			},
			getList() {
				if (this.$store.state.user.profile.id == 1) {
					this.listQuery.isMasterAccount = 1
				}
				this.loading = true
				getList(this.listQuery).then(res => {
					
					this.list = res.data
					this.total = Number(res.page.total)
					this.loading = false
				})
			},
			changeUserStatus(e, row) {
				changeStatus(row.userId).then(response => {
					this.$message.success('操作成功')
					this.getList()
				})
			},
			changeUserQuery(e, row) {
				changeQuery(row.userId).then(response => {
					this.$message.success('操作成功')
					this.getList()
				})
			},
			changeUserDownload(e, row) {
				changeDownload(row.userId).then(response => {
					this.$message.success('操作成功')
					this.getList()
				})
			},
			changeUserDeploy(e, row) {
				changeDeploy(row.userId).then(response => {
					this.$message.success('操作成功')
					this.getList()
				})
			},
			resetForm() {
				this.form = {
					userName: '', //用户
					fullName: '', //真实姓名
					password: '', //密码
					rePassword: '', //确认密码
					status: 1
				}
			},
			add() {
				this.resetForm()
				this.showPassword = true
				this.resetRules()
				this.title = '添加子账号'
				this.visible = true
				this.isAdd = true
			},
			resetRules() {
				if (this.showPassword) {
					this.rules.password[0].required = true
					this.rules.rePassword[0].required = true
				} else {
					this.rules.password[0].required = false
					this.rules.rePassword[0].required = false
				}
			},
			view(id) {
				if (id) {
					check(id).then(res => {
						this.showPassword = false
						this.title = '查看子账号'
						this.form = res.data
						this.isAdd = false
						this.visible = true
					})
				}
			},
			edit(row) {
				// if (id) {
				// 	check(id).then(res => {
						this.showPassword = false
						this.title = '修改子账号'
						this.form = JSON.parse(JSON.stringify(row))
						this.isAdd = true
						this.visible = true
				// 	})
				// }
			},
			remove(id = null) {
				let ids = []
				let that = this
				if (id) {
					ids.push(id)
				} else {
					if (!this.selRows.length) {
						this.$message.error('请选择需要删除的数据')
						return false
					} else {
						for (let i in this.selRows) {
							ids = this.selRows
						}
					}
				}
				this.$confirm({
					title: '提示',
					content: '确定删除将清空所有该账户关联的数据，请问确定吗？',
					cancelText: '取消',
					okText: '确定',
					onOk() {
						remove(ids).then(res => {
							that.$message.success('操作成功')
							that.getList()
						})
					},
					onCancel() {},
				});
			},
			handleOk() {
				this.$refs.form.validate(valid => {
					if (valid) {
						if (this.validPasswd()) {
							let data = JSON.parse(JSON.stringify(this.form))
							delete data.rePassword
							this.confirmLoading = true
							let url = 'user/add'
							if (data.userId) {
								data = {
									userId: data.userId,
									fullName: data.fullName,
									status: data.status,
									queryPerm: data.queryPerm
								}
								url = 'user/update'
							}else {
								delete data.status
							}
							save(url, data).then(res => {
								this.confirmLoading = false
								this.$message.success('操作成功')
								this.getList()
								this.visible = false
							}, err => {
								this.confirmLoading = false
							})
						}
					}
				});
			},
			validPasswd() {
				if (!this.showPassword) {
					return true
				}
				if (this.form.password !== this.form.rePassword) {
					this.$message.error('两次密码不一致')
					return false
				}
				if (this.form.password === '' || this.form.rePassword === '') {
					this.$message.error('密码不能为空')
					return false
				}
				return true
			},
			handleCancel() {
				this.visible = false
				this.$refs.form.resetFields()
			},
			openRole(id = null, isMasterAccount = null) {
				this.isMasterAccount = isMasterAccount
				if (id) {
					roleTreeListByIdUser(id).then(response => {
						roleList().then(res => {
							this.nowId = id
							this.makeRole(response.data.list, res.data.list)
							this.roleDialog.visible = true

						})
					})
				}
			},
			makeRole(ownRole, roleList) {
				let tmpSysRole = [];
				let tmpComRole = [];
				let tmpPrivateRole = [];
				let checkedSysRole = '';
				let checkedComRole = [];
				let checkedPrivateRole = [];
				ownRole.forEach(item => {
					roleList.forEach(m => {
						item.isCheck = false
						if (item.roleId == m.roleId) {
							m.isCheck = true
						}
					})
				})

				this.roleDialog.roles.allRoles = roleList

				for (let r in this.roleDialog.roles.allRoles) {
					tmpComRole.push(roleList[r])
					if (roleList[r].isCheck) {
						checkedComRole.push(roleList[r].roleId)
					}
				}
				this.roleDialog.roles.commonRoles = tmpComRole
				this.roleDialog.checkedComRoleKeys = checkedComRole
			},
			handleCheckedComRole(val) {
				this.roleDialog.checkedComRoleKeys = val
			},
			setRole() {
				var newroleIdList = this.roleDialog.checkedPrivateRoleKeys.concat(this.roleDialog.checkedComRoleKeys)
				if (this.roleDialog.checkedSysRoleKeys) newroleIdList.push(this.roleDialog.checkedSysRoleKeys)
				var data = {
					userId: this.nowId,
					roleIdList: newroleIdList
				}
				if (this.userId != 1 && this.isMasterAccount == 1) data.vipLevelId = this.nowVIPId
				setRole(data).then(response => {
					this.roleDialog.visible = false
					this.$message.success('操作成功')
					this.getList()
				})
			},
			resetPassword() {
				let that = this
				this.$confirm({
					title: '是否重置此子账号密码？',
					content: '重置后账号密码为：123456',
					cancelText: '取消',
					okText: '重置',
					onOk() {
						restPassword(that.form.userId).then(response => {
							that.roleDialog.visible = false
							that.$message.success('操作成功')
							that.visible = false
						})
					},
					onCancel() {}
				})

			},
		}
	}
</script>

<style lang="scss" scoped>
	/deep/.tableHiden {
		display: none;
	}
	/deep/.ant-table-thead > tr > th {
	  // text-align: center;
	  font-size: 13px;
	  color: #fff;
	  background-color: #0083E6;
	}
	/deep/.ant-table-tbody > tr:nth-of-type(2n) {
	  background-color: rgba(244,244,244,1);
	}
	/deep/.add-btn {
		background: #0083E6;
		// border: none;
	}
</style>