import Cookies from 'js-cookie'

const state = {
	sidebar: {
		opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
		withoutAnimation: false
	},
	device: 'desktop',
	language: Cookies.get('language') || 'zh',
	size: Cookies.get('size') || 'medium'
}

const mutations = {
	TOGGLE_SIDEBAR: state => {
		state.sidebar.opened = !state.sidebar.opened
		state.sidebar.withoutAnimation = false
		if (state.sidebar.opened) {
			Cookies.set('sidebarStatus', 1)
		} else {
			Cookies.set('sidebarStatus', 0)
		}
	},
	CLOSE_SIDEBAR: (state, withoutAnimation) => {
		Cookies.set('sidebarStatus', 0)
		state.sidebar.opened = false
		state.sidebar.withoutAnimation = withoutAnimation
	},
	TOGGLE_DEVICE: (state, device) => {
		state.device = device
	},
	SET_LANGUAGE: (state, language) => {
		state.language = language
		Cookies.set('language', language)
	}
}

const actions = {
	toggleSideBar({
		commit
	}) {
		commit('TOGGLE_SIDEBAR')
	},
	closeSideBar({
		commit
	}, {
		withoutAnimation
	}) {
		commit('CLOSE_SIDEBAR', withoutAnimation)
	},
	toggleDevice({
		commit
	}, device) {
		commit('TOGGLE_DEVICE', device)
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}