<template>
	<div id="app">
		<a-config-provider :locale="zhCN">
			<router-view />
		</a-config-provider>
	</div>
</template>

<script>
	import zhCN from 'ant-design-vue/es/locale/zh_CN'
	import dayjs from 'dayjs'
	import 'dayjs/locale/zh-cn'
	dayjs.locale('en')
	export default {
		data () {
			return {
				zhCN
			}
		}
	}
</script>
<style>
	.zidingyiMsg {
		width: auto !important;
	}
</style>