import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/register', '/passwd'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
	// start progress bar
	NProgress.start()
	// set page title

	// determine whether the user has logged in
	const hasToken = getToken()
	if (hasToken) {
		if (to.path === '/login') {
			
			next()
			NProgress.done()
		}else {
			const hasRoles = store.getters.roles && store.getters.roles.length > 0
			if (hasRoles) {
			  next()
			} else {
			  try {
			    // get user info
			    store.dispatch('tagsView/delAllViews')
			    store.dispatch('user/getInfo')
			    const accessRoutes  = await store.dispatch('menu/getSideMenus');
			    router.addRoutes(accessRoutes)
			    next({ ...to, replace: true })
			  } catch (error) {
			    // remove token and go to login page to re-login
			    // await store.dispatch('user/resetToken')
			    next(`/login?redirect=${to.path}`)
			    NProgress.done()
			  }
			}
		}
	} else {	
		/* has no token*/
		if (whiteList.indexOf(to.path) !== -1) {
			// in the free login whitelist, go directly
			next()
			NProgress.done()
		} else {
			// other pages that do not have permission to access are redirected to the login page.
			next(`/login`)
			NProgress.done()
		}
	}
})

router.afterEach(() => {
	// finish progress bar
	NProgress.done()
})
