import request from '@/utils/request'

export function getList(params) {
  return request({
    url: 'user/page',
    method: 'get',
    params
  })
}

export function save(url,params) {
  return request({
    url: url,
    method: 'post',
    data:params
  })
}

export function remove(id) {
  return request({
    url: 'user/delete',
    method: 'delete',
    data:id
  })
}

export function check(userId) {
  return request({
    url: 'user/info/' + userId,
    method: 'get'
  })
}
export function changeStatus(userId) {
  return request({
    url: 'user/updateStatus',
    method: 'post',
    params:{
      userId
    }
  })
}
export function changeQuery(userId) {
  return request({
    url: `user/sub/all/order/query/status/${userId}`,
    method: 'post',
  })
}
export function changeDownload(userId) {
  return request({
    url: `user/sub/code/download/status/${userId}`,
    method: 'post',
  })
}

export function changeDeploy(userId) {
  return request({
    url: `user/sub/deploy/status/${userId}`,
    method: 'post',
  })
}

export function setRole(params) {
  return request({
    url: 'user/saveUserRole',
    method: 'post',
    data: params
  })
}
export function restPassword(userId) {
  return request({
    url: 'user/password/reset/' + userId,
    method: 'post'
  })
}


export function deleteRole(ids) {
  return request({
    url: 'user/deleteRole',
    method: 'delete',
    data: ids
  })
}

export function getEnUserList(params, id) {
  return request({
    url: 'user/list/exclude/' + id,
    method: 'get',
    params
  })
}